import Homepage from  "./pages/Homepage"


function App() {
  return (
    <main>
      <Homepage />
    </main>
  );
}

export default App;
